

import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';



import React from "react"
import i18next from '../i18n/config';
// import ImgHeader from "../img/A_glowna_1/tlo_glowna.jpg";
import ImgIconMail from "../img/icon/iko_koperta.png";
import ImgIconDocs from "../img/icon/iko_dokumenty.png";




export default function Header(props) {






    if (typeof document !== `undefined`) {



        document.matrixInit = function () {

            //                   Initialising the canvas
            document.matrix_canvas = document.getElementById('matrix1');
            document.matrix_ctx = document.matrix_canvas.getContext('2d');

            // Setting the width and height of the canvas
            document.matrix_canvas.width = window.innerWidth;
            document.matrix_canvas.height = window.innerHeight - 120;

            // Setting up the letters
            document.matrix_letters = 'INSTANTA';
            document.matrix_letters = document.matrix_letters.split('');

            // Setting up the columns
            document.matrix_fontSize = 16;
            var columns = document.matrix_canvas.width / document.matrix_fontSize;

            // Setting up the drops
            document.matrix_drops = [];
            for (var i = 0; i < columns; i++) {
                document.matrix_drops[i] = 1;
            }

            // Loop the animation
            setInterval(function () {
                document.matrixStart()
            }, 133);

        }

        document.matrixStart = function () {
            document.matrix_ctx.fillStyle = 'rgba(0, 0, 0, .1)';
            document.matrix_ctx.fillRect(0, 0, document.matrix_canvas.width, document.matrix_canvas.height);
            for (var i = 0; i < document.matrix_drops.length; i++) {
                var text = document.matrix_letters[Math.floor(Math.random() * document.matrix_letters.length)];
                document.matrix_ctx.fillStyle = '#DAA520';
                document.matrix_ctx.fillText(text, i * document.matrix_fontSize, document.matrix_drops[i] * document.matrix_fontSize);
                document.matrix_drops[i]++;
                if (document.matrix_drops[i] * document.matrix_fontSize > document.matrix_canvas.height && Math.random() > .95) {
                    document.matrix_drops[i] = 0;
                }
            }
        }




        setTimeout(function () {
            document.matrixInit()
        }, 444);




    }

    return (
            <React.Fragment>

                <canvas id='matrix1'></canvas>
                <div class="clouds">
                    <div class="cloud">
                        <a href='https://edokument.instanta.pl/online'>
                            <img loading="lazy" src={ImgIconDocs} alt="Instanta Documents" />
                            <p>{i18next.t('CloudLink1')}</p>
                        </a>
                    </div>

                    <div class="cloud">
                        <a href='https://outlook.instanta.coffee/owa/'>
                            <img loading="lazy" src={ImgIconMail} alt="Instanta Coffee" />
                            <p>{i18next.t('CloudLink2')}</p>
                        </a>
                    </div>

                    <div class="cloud">
                        <a href='https://outlook.instanta.pl/SOGo/'>
                            <img loading="lazy" src={ImgIconMail} alt="Instanta Asia" />
                            <p>{i18next.t('CloudLink3')}</p>
                        </a>
                    </div>

                    <div class="cloud">
                        <a href='https://outlook.instanta.pl/SOGo/'>
                            <img loading="lazy" src={ImgIconMail} alt="Instanta Colombia" />
                            <p>{i18next.t('CloudLink4')}</p>
                        </a>
                    </div>

                    <div class="cloud">
                        <a href='https://outlook.instanta.pl/SOGo/'>
                            <img loading="lazy" src={ImgIconMail} alt="Instanta Kama-Vitrum" />
                            <p>{i18next.t('CloudLink6')}</p>
                        </a>
                    </div>

                </div>
            </React.Fragment>

            )

}
